<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items flex-wrap marginBottom10">


          <div class="flex align-items marginRight10 marginBottom10">
            <span class="span marginRight10">下单时间: </span>
            <div class="width300">
              <el-date-picker
                  v-model="date"
                  type="daterange"
                  range-separator="~"
                  @change="handleDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
          <div class="width200 marginRight10 marginBottom10">
            <el-select v-model="searchData.state" clearable >
              <el-option value="" label="订单状态" />
              <el-option :value="5" label="待付款" />
              <el-option :value="10" label="待发货" />
              <el-option :value="15" label="待收货" />
              <el-option :value="20" label="已完成" />
              <el-option :value="1" label="已关闭" />
            </el-select>
          </div>
          <el-input size="medium" v-model="searchData.code" :maxlength="100" clearable placeholder="输入订单编号" class="marginRight10 width200 marginBottom10"/>
          <el-input size="medium" v-model="searchData.nickname" :maxlength="100" clearable placeholder="输入下单人" class="marginRight10 width200 marginBottom10"/>
          <el-input size="medium" v-model="searchData.phone" :maxlength="100" clearable placeholder="输入下单人手机号" class="marginRight10 width200 marginBottom10"/>
          <el-input size="medium" v-model="searchData.supplierName" :maxlength="100" clearable placeholder="输入供应商名称" class="marginRight10 width200 marginBottom10"/>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset">重置</el-button>
          </div>
          <div class="marginRight10 marginBottom10">
            <el-button size="medium" style="background: #c280ff;color: #ffffff;border-color: #c280ff" icon="el-icon-download" @click="handleExport">导出</el-button>
          </div>
        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-notebook-2" @click="handleView(row)">查看详情</el-button>
        <el-button type="text" size="mini" icon="el-icon-position" v-if="row.state === 10" @click="handleDeliver(row)">发货</el-button>
      </template>
    </avue-crud>

    <el-dialog
        title="发货"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="submit">
        <template slot="expressNumber">
          <el-input v-model="form.expressNumber"
                    placeholder="请输入 快递单号"
                    maxlength="30"
                    :show-word-limit="true"
                    type="text"
                    @input="changeSkuCode"/>
        </template>
        <template slot="tips">
          <div style="font-size: 14px;color: #606266;"><span style="color: #ff0000">*</span>无需物流则不需要填写物流信息，确定后订单状态为已完成</div>
        </template>
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { list,deliver } from '@/api/shop/order'
export default {
  name: "index",
  data(){
    return{
      date: [],
      searchData: {
        createTimeStart: '',
        createTimeEnd: '',
        state: '',
        code: '',
        nickname: '',
        phone: '',
        supplierName: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option:{
        title:'订单管理',
        titleSize:'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align:'center',
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '订单编号',
            prop: 'code'
          },
          {
            label: '下单人',
            prop: 'nickname'
          },
          {
            label: '下单人手机号',
            prop: 'userPhone'
          },
          {
            label: '订单总额',
            prop: 'total'
          },
          {
            label: '下单时间',
            prop: 'createTime'
          },
          {
            label: '收货人',
            prop: 'username'
          },
          {
            label: '收货人手机号',
            prop: 'phone'
          },
          {
            label: '供应商名称',
            prop: 'supplierName'
          },
          {
            label: '订单状态',
            prop: 'state',
            formatter: function(row, column, cellValue){
              // console.log(row, column, cellValue, index)
              if(cellValue === 1){
                return '已关闭'
              }else if(cellValue === 5){
                return '待支付'
              }else if(cellValue === 10){
                return '待发货'
              }else if(cellValue === 15){
                return '待收货'
              }else if(cellValue === 20){
                return '已完成'
              }
            }
          }
        ]
      },

      dialogVisible: false,
      form: {
        id: '',
        code: '',
        supplierName: '',
        expressCompany: '',
        expressNumber: '',
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        column: [
          {
            label: '订单编号',
            prop: 'code',
            span: 24,
            row: true,
            disabled: true
          },
          {
            label: '供应商名称',
            prop: 'supplierName',
            span: 24,
            row: true,
            disabled: true
          },
          {
            label: '物流公司',
            prop: 'expressCompany',
            span: 24,
            row: true,
            maxlength: 10,
            showWordLimit: true,
            // rules: [{
            //   required: true,
            //   message: "请输入物流公司",
            //   trigger: "blur"
            // }]
          },
          {
            label: '快递单号',
            prop: 'expressNumber',
            span: 24,
            row: true,
            maxlength: 30,
            showWordLimit: true,
            // rules: [{
            //   required: true,
            //   message: "请输入快递单号",
            //   trigger: "blur"
            // }]
          },
          {
            label: '',
            labelWidth: 0,
            prop: 'tips',
            span: 24,
          }
        ]
      }
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false
        if(res.code === 200){
          this.tableData = res.data.records
          this.page.total = res.data.total
        }
      })
    },
    // 搜索
    handleSearch(){
      // console.log('搜索')
      this.page.currentPage = 1
      this.onLoad()
    },
    // 重置
    handleReset(){
      this.date = [];
      this.searchData = {
        createTimeStart: '',
        createTimeEnd: '',
        state: '',
        code: '',
        nickname: '',
        phone: '',
        supplierName: ''
      }
      this.page.currentPage = 1
      this.onLoad()
    },
    // 导出
    handleExport(){
      // console.log('导出')
      this.$confirm('确定导出数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let url = `/api/web/shop/order/export?createTimeStart=${this.searchData.createTimeStart}&createTimeEnd=${this.searchData.createTimeEnd}&state=${this.searchData.state}&code=${this.searchData.code}&nickname=${this.searchData.nickname}&phone=${this.searchData.phone}&supplierName=${this.searchData.supplierName}`;
        let date = new Date();
        let time = date.getFullYear() + '' + (date.getMonth() + 1) + '' + date.getDate() + '' + date.getHours() + '' + date.getMinutes() + '' + date.getSeconds();
        this.global.exportExcel(url, `订单管理-${time}.xlsx`);
      })
    },
    // 查看详情
    handleView(row){
      // console.log('查看详情',row)
      this.$router.push({
        path: '/shop/order/info',
        query: {
          id: row.id
        }
      })
    },
    // 发货
    handleDeliver(row){
      console.log('发货',row)
      this.form = {
        id: row.id,
        code: row.code,
        supplierName: row.supplierName,
        expressCompany: '',
        expressNumber: '',
      }
      this.dialogVisible = true
    },
    // 选择时间
    handleDate(val){
      if(val.length > 0){
        this.searchData.createTimeStart = val[0] + ' 00:00:00'
        this.searchData.createTimeEnd = val[1] + ' 23:59:59'
      }else{
        this.searchData.createTimeStart = ''
        this.searchData.createTimeEnd = ''
      }
    },
    // 提交
    submit(form,done){
      // console.log('提交',form)
      deliver({
        id: form.id,
        expressCompany: form.expressCompany,
        expressNumber: form.expressNumber
      }).then(res => {
        if(res.code === 200){
          this.$message.success('发货成功')
          this.dialogVisible = false;
          setTimeout(()=>{
            this.resetForm();
          },500)
          this.onLoad()
        }else{
          this.$message.error(res.msg)
          done()
        }
      }).catch(()=> done())
    },
    // 关闭
    handleClose(){
      this.dialogVisible = false;
      setTimeout(()=>{
        this.resetForm();
      },500)
    },
    // 重置form
    resetForm(){
      this.form = {
        id: '',
        code: '',
        supplierName: '',
        expressCompany: '',
        expressNumber: '',
      }
    },
    changeSkuCode(e){
      const pattern = '^[0-9a-zA-z]{0,30}$';
      const regExp = new RegExp(pattern)
      if(e.match(regExp)) {
        this.form.expressNumber = e;
      }else{
        this.form.expressNumber = '';
        e = '';
      }
    }
  }
}
</script>

<style scoped>

</style>